var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "설비 고장 목록",
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          gridHeight: _vm.grid.height,
          isTitle: true,
          columnSetting: false,
          isFullScreen: false,
          usePaging: false,
          expandAll: true,
          hideBottom: true,
          isExcelDown: false,
          filtering: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props }) {
              return [
                [
                  _c("q-btn", {
                    attrs: {
                      flat: "",
                      size: "12px",
                      color: "orange",
                      icon: "chevron_right",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.rowClickDetail(props.row, props.pageIndex)
                      },
                    },
                  }),
                ],
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.breakRemark),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("현상"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: true,
                        codeGroupCd: "WO_BD_PHENOMENON_CD",
                        valueText: "codeName",
                        valueKey: "code",
                        isArray: true,
                        label: "",
                        name: "phenomenons",
                      },
                      model: {
                        value: _vm.selectedRow.phenomenons,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "phenomenons", $$v)
                        },
                        expression: "selectedRow.phenomenons",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("원인"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: true,
                        codeGroupCd: "WO_BD_CAUSE_CD",
                        valueText: "codeName",
                        valueKey: "code",
                        isArray: true,
                        label: "",
                        name: "causes",
                      },
                      model: {
                        value: _vm.selectedRow.causes,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "causes", $$v)
                        },
                        expression: "selectedRow.causes",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("조치"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: true,
                        codeGroupCd: "WO_BD_ACTION_CD",
                        valueText: "codeName",
                        isArray: true,
                        valueKey: "code",
                        label: "",
                        name: "actions",
                      },
                      model: {
                        value: _vm.selectedRow.actions,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "actions", $$v)
                        },
                        expression: "selectedRow.actions",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("추가 조치 사항"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "",
                        name: "dialogcheckResult",
                      },
                      model: {
                        value: _vm.selectedRow.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "remark", $$v)
                        },
                        expression: "selectedRow.remark",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }